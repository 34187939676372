import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { CgMenuRound } from "react-icons/cg"
import { AiFillCloseCircle } from "react-icons/ai"

const Nav = styled.nav`
  position: fixed;
  background: #ffffff;
  z-index: 100;
  width: 100%;
  left: 0;
  top: 0;
  @media (max-width: 1280px) {
    display: none;
  }
  h1 {
    padding: 0;
    font-weight: 100;
    margin: 0;
    font-size: 22px;
    padding: 20px 0;
  }
  .nav-grid {
    width: 75%;
    margin: 0 auto;
    div {
      background: none;
    }
    .relative-ul {
      margin-left: 180px;
      @media (max-width: 1280px) {
        margin-left: 0;
        margin-top: 40px;
        width: 220px;
      }
    }
  }
  .title {
    margin-top: 10px;
    padding-bottom: 50px;
    a {
      text-decoration: none;
      color: #000000;
      font-size: 32px;
    }
  }
`

const NavMobile = styled.nav`
  position: fixed;
  z-index: 120;
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: scroll;
  left: 0;
  top: 0;
  @media (min-width: 1281px) {
    display: none;
  }
  h1 {
    padding: 0;
    font-weight: 100;
    margin: 0;
    font-size: 22px;
    padding: 50px 0 25px 0;
    text-align: center;
  }
  .nav-grid {
    width: 100%;
    margin: 0 auto;
    div {
      background: white;
    }
    .relative-ul {
      @media (max-width: 1280px) {
        margin-left: 0;
        margin-top: 40px;
        width: 100%;
        text-align: center;
      }
    }
  }
`

const Ul = styled.ul`
  width: 200px;
  position: absolute;
  padding: 0 10px;
  margin: 0;
  background: lightgrey;
  .classMarker {
    font-weight: 900;
  }
  li {
    list-style-type: none;
    a {
      font-size: 18px;
      color: #000000;
      padding: 10px 0;
      display: block;
      color: #000000;
      &:hover {
        background: aqua;
      }
    }
    .archives-anchor {
      //background:#000000;
      width: 100%;
      display: block;
      font-weight: bold;
      display: grid;
      align-items: center;
      //height:30px;
      //padding:10px;
      &:hover {
        background: lightgrey;
        cursor: pointer;
        padding: 10px 0;
      }
    }
  }
  ul {
    padding: 0;
  }
`
const UlMobile = styled.ul`
  width: 100%;
  position: absolute;
  padding: 0;
  margin: 0;
  .classMarker {
    font-weight: 900;
  }
  li {
    list-style-type: none;
    a {
      font-size: 18px;
      color: #000000;
      padding: 10px 0;
      display: block;
      color: #000000;
    }
  }
  .archives-anchor {
    text-align: center;
    font-size: 18px;
    color: #000000;
    font-weight: 900;
  }
  ul {
    padding: 0;
  }
`

const UlRelative = styled.ul`
  margin: 0;
  @media (max-width: 1280px) {
    padding: 0;
  }
  li {
    list-style-type: none;
    //background: lightgrey;
    display: inline-block;
    @media (max-width: 1280px) {
      display: list-item;
    }
    margin: 0;
    padding: 10px;
    //width:200px;
    a {
      font-size: 18px;
      color: #000000;
      font-weight: 900;
      text-decoration: none;
      span{
        font-size:12px;
      }
    }
  }
`

const MenuButton = styled.button`
  position: fixed;
  margin: 0 auto;
  display: block;
  background: none;
  border: none;
  z-index: 200;
  &:focus {
    outline: none;
  }
  @media (min-width: 1281px) {
    display: none;
  }
`

const MoveUl = styled.ul`
  background: #ffffff;
  width: 100%;
  text-align: center;
  li {
    //margin-left: 150px;
  }
  a {
    font-weight: 900;
    font-size: 20px;
  }
`

const Title = styled.div`
  text-align: center;
  padding-top: 25px;
  h1 {
    font-size: 32px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
  }
`

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      dropdownOpenMobile: false,
      mobileMenu: false,
    }
    this.toggle = this.toggle.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMenuClickToggle = this.onMenuClickToggle.bind(this)
    this.toggleMobile = this.toggleMobile.bind(this)
    this.resetOverflow = this.resetOverflow.bind(this)
  }
  toggle() {
    this.setState(prevState => ({
      mobileMenu: !prevState.dropdownOpen,
    }))
  }
  toggleMobile() {
    this.setState(prevState => ({
      dropdownOpenMobile: !prevState.dropdownOpenMobile,
    }))
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true })
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false })
  }
  onMenuClickToggle() {
    var x = document.getElementsByTagName("BODY")[0]
    console.log(x)
    x.classList.toggle("hidden-overflow")
    this.setState(prevState => ({
      mobileMenu: !prevState.mobileMenu,
    }))
  }

  resetOverflow() {
    var x = document.getElementsByTagName("BODY")[0]
    console.log(x)
    x.classList.remove("hidden-overflow")
    this.setState(prevState => ({
      mobileMenu: !prevState.mobileMenu,
    }))
  }

  render() {
    const activeStyles = {
      textDecoration: "underline",
      background: "pink",
      padding: "10px",
    }

    return (
      <>
        <MenuButton onClick={this.onMenuClickToggle}>
          {!this.state.mobileMenu ? (
            <>
              <CgMenuRound size={30} />
              <p>Main Menu</p>
            </>
          ) : (
            <AiFillCloseCircle size={30} />
          )}
        </MenuButton>
        <Title>
          <Link to="/">
            <h1>Mrs Kitson's Kindergarten</h1>
          </Link>
        </Title>
        {/* {!this.state.mobileMenu ? ( */}
        <Nav>
          <div className="nav-grid">
            {/* <a 
            onMouseOver={event => this.showNav(true, event)} 
            href=""
          >
            Archives
          </a> */}
            <div>
              <div className="title">
                <Link to="/">{this.props.title}</Link>
              </div>
              <Ul
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
              >
                {!this.state.dropdownOpen && (
                  <li>
                    <a className="archives-anchor">Archives</a>
                  </li>
                )}
                {this.state.dropdownOpen && (
                  <>
                    <li>
                      <a className="archives-anchor">Archives</a>
                    </li>
                    <li>
                      <Link to="/TwentyTenPosts/">2010-2011 Archives</Link>
                    </li>
                    <li>
                      <Link to="/TwentyElevenPosts/">2011-2012 Archives</Link>
                    </li>
                    <li>
                      <Link to="/TwentyTwelvePosts/">2012-2013 Archives</Link>
                    </li>
                    <li>
                      <Link to="/TwentyThirteenPosts/">2013-2014 Archives</Link>
                    </li>
                    <li>
                      <Link to="/TwentyFourteenPosts/">2014-2015 Archives</Link>
                    </li>
                    <li>
                      <Link to="/TwentyFifteenPosts/">2015-2016 Archives</Link>
                    </li>
                    <li>
                      <Link to="/TwentySixteenPosts/">2016-2017 Archives</Link>
                    </li>
                    <ul>
                      <li className="classMarker">KT</li>
                      <ul>
                        <li>
                          <Link to="/TwentySeventeenKtPosts/">
                            2017-2018 KT Archives
                          </Link>
                        </li>
                        <li>
                          <Link to="/TwentyEighteenKtPosts/">
                            2018-2019 KT Archives
                          </Link>
                        </li>
                        <li>
                          <Link to="/TwentyNineteenKtPosts/">
                            2019-2020 KT Archives
                          </Link>
                        </li>
                        <li>
                          <Link to="/TwentyTwentyKtPosts/">
                            2020-2021 KT Archives
                          </Link>
                        </li>
                        <li>
                          <Link to="/TwentyTwentyOneKtPosts/">
                            2021-2022 KT Archives
                          </Link>
                        </li>
                        <li>
                          <Link to="/TwentyTwentyTwoKtPosts/">
                            2022-2023 KT Archives
                          </Link>
                        </li>
                      </ul>
                    </ul>

                    <ul>
                      <li className="classMarker">KM</li>
                      <li>
                        <Link to="/TwentySeventeenKmPosts/">
                          2017-2018 KM Archives
                        </Link>
                      </li>
                      <li>
                        <Link to="/TwentyEighteenKmPosts/">
                          2018-2019 KM Archives
                        </Link>
                      </li>
                      <li>
                        <Link to="/TwentyNineteenKmPosts/">
                          2019-2020 KM Archives
                        </Link>
                      </li>
                      <li>
                        <Link to="/TwentyTwentyKmPosts/">
                          2020-2021 KM Archives
                        </Link>
                      </li>
                      <li>
                        <Link to="/TwentyTwentyOneKmPosts/">
                          2021-2022 KM Archives
                        </Link>
                      </li>
                      <li>
                        <Link to="/TwentyTwentyTwoKmPosts/">
                          2022-2023 KM Archives
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </Ul>
            </div>
            <div className="relative-ul">
              <UlRelative>
                <li>
                  <Link
                    activeStyle={activeStyles}
                    to="/TwentyTwentyThreeKmPosts/"
                  >
                    KM Blog 2023
                  </Link>
                </li>
                <li>
                  <Link
                    activeStyle={activeStyles}
                    to="/TwentyTwentyThreeKtPosts/"
                  >
                    KT Blog 2023
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/calendar/">
                    Calendar
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/curriculum/">
                    Curriculum
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/newsletter/">
                    Newsletter
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/resources/">
                    Resources
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/orientation/">
                    Orientation
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/classroom/">
                    Classroom
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyles} to="/preparation/">
                    <span>2023/2024</span> Preparation
                  </Link>
                </li>
              </UlRelative>
            </div>
          </div>
        </Nav>
        {/* ) : ( */}
        {this.state.mobileMenu && (
          <NavMobile>
            <div className="nav-grid">
              {/* <a 
            onMouseOver={event => this.showNav(true, event)} 
            href=""
          >
            Archives
          </a> */}
              <div>
                <Link to="/">
                  <h1>{this.props.title}</h1>
                </Link>
                <UlMobile
                  //onMouseOver={this.onMouseEnter}
                  //onMouseLeave={this.onMouseLeave}
                  // isOpen={this.state.dropdownOpenMobile}
                  //toggle={this.toggle}
                  onClick={this.toggleMobile}
                >
                  <li>
                    <a className="archives-anchor">
                      {this.state.dropdownOpenMobile ? (
                        <span>
                          Archives <AiFillCloseCircle />
                        </span>
                      ) : (
                        <span>Archives</span>
                      )}
                    </a>
                  </li>
                  {this.state.dropdownOpenMobile && (
                    <MoveUl>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentyTenPosts/"
                        >
                          2010-2011
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentyElevenPosts/"
                        >
                          2011-2012
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentyTwelvePosts/"
                        >
                          2012-2013
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentyThirteenPosts/"
                        >
                          2013-2014
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentyFourteenPosts/"
                        >
                          2014-2015
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentyFifteenPosts/"
                        >
                          2015-2016
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={this.resetOverflow}
                          to="/TwentySixteenPosts/"
                        >
                          2016-2017
                        </Link>
                      </li>
                      <ul>
                        <li className="classMarker">KT</li>
                        <ul>
                          <li>
                            <Link
                              onClick={this.resetOverflow}
                              to="/TwentySeventeenKtPosts/"
                            >
                              2017-2018 KT
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.resetOverflow}
                              to="/TwentyEighteenKtPosts/"
                            >
                              2018-2019 KT
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.resetOverflow}
                              to="/TwentyNineteenKtPosts/"
                            >
                              2019-2020 KT
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.resetOverflow}
                              to="/TwentyTwentyKtPosts/"
                            >
                              2020-2021 KT
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.resetOverflow}
                              to="/TwentyTwentyOneKtPosts/"
                            >
                              2021-2022 KT
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.resetOverflow}
                              to="/TwentyTwentyTwoKtPosts/"
                            >
                              2022-2023 KT
                            </Link>
                          </li>
                        </ul>
                      </ul>

                      <ul>
                        <li className="classMarker">KM</li>
                        <li>
                          <Link
                            onClick={this.resetOverflow}
                            to="/TwentySeventeenKmPosts/"
                          >
                            2017-2018 KM
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.resetOverflow}
                            to="/TwentyEighteenKmPosts/"
                          >
                            2018-2019 KM
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.resetOverflow}
                            to="/TwentyNineteenKmPosts/"
                          >
                            2019-2020 KM
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.resetOverflow}
                            to="/TwentyTwentyKmPosts/"
                          >
                            2020-2021 KM
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.resetOverflow}
                            to="/TwentyTwentyOneKmPosts/"
                          >
                            2021-2022 KM
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={this.resetOverflow}
                            to="/TwentyTwentyTwoKmPosts/"
                          >
                            2022-2023 KM
                          </Link>
                        </li>
                      </ul>
                    </MoveUl>
                  )}
                </UlMobile>
              </div>
              <div className="relative-ul">
                <UlRelative>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/TwentyTwentyThreeKmPosts/"
                    >
                      KM Blog 2023
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/TwentyTwentyThreeKtPosts/"
                    >
                      KT Blog 2023
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/calendar/"
                    >
                      Calendar
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/curriculum/"
                    >
                      Curriculum
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/newsletter/"
                    >
                      Newsletter
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/resources/"
                    >
                      Resources
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/orientation/"
                    >
                      Orientation
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeStyle={activeStyles}
                      onClick={this.resetOverflow}
                      to="/classroom/"
                    >
                      Classroom
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.resetOverflow}
                      activeStyle={activeStyles}
                      to="/preparation/"
                    >
                      <span>2023/2024</span> Preparation
                    </Link>
                  </li>
                </UlRelative>
              </div>
            </div>
          </NavMobile>
        )}
      </>
    )
  }
}

const Header = ({ siteTitle, location }) => (
  <header>
    <div>
      <Menu location={location} title={siteTitle} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
